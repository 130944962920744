.close-alert {
  position: absolute;
  top: 12px;
  right: 12px;
  cursor: pointer;
}

.alert-body {
  flex: 1 1 auto;
  padding-bottom: 3rem;

  .alert {
    margin-bottom: 0 !important;
    padding: 1.5rem;
  }

  .close-alert {
    top: 22px;
  }
}

.alert {

  padding-right: 35px !important;

  .icon-float {
    float: left;
    margin-top: 3px;
    margin-right: 6px;
  }

  a {
    margin: 0 2px;
    border-bottom: solid 1px;

    &:hover, &:focus {
      text-decoration: none;
    }
  }

  &.alert-info {
    a {
      color: #0c5460;
    }
  }

  &.alert-success {
    a {
      color: #155724;
    }
  }

  &.alert-danger {
    a {
      color: #721c24;
    }
  }

  &.alert-warning {
    a {
      color: #856404;
    }
  }

}