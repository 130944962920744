table {

  .action-button {
    white-space: nowrap;
    width: 25px;

    > a, > span {
      padding: 0.25rem 0.5rem;
      font-size: 0.875rem;
      border-radius: 0.2rem;
      margin-right: 4px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  th, td {
    color: #FFF;
    height: 30px !important;
    vertical-align: middle !important;
    width: auto;
    padding: 5px !important;
    font-size: 14px !important;
    text-transform: uppercase;
    background-color: #373A47 !important;
    border: 1px solid rgba(255, 255, 255, 0.1) !important;
    border-bottom: 1px solid #FFFFFF !important;
    font-weight: 700;
    -webkit-box-sizing: content-box;
    box-sizing: content-box;

    input {
      width: 95%;
      margin-left: 2.5%;
    }
  }

  td {
    background-color: #FFFFFF !important;
    color: black !important;
    font-weight: normal;
    border-bottom-color: #DDDDDD !important;
  }

}