// assets/css/global.scss

// customize some Bootstrap variables
$fa-font-path: "~font-awesome/fonts";
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$primary-color: #09B9D3;
$darker-primary-color: darken(#09B9D3, 20%);

// the ~ allows you to reference things in node_modules
@import '~font-awesome/scss/font-awesome';
@import "~lightslider/dist/css/lightslider.min.css";
@import '~lightbox2/dist/css/lightbox.min.css';
@import '~bootstrap/scss/bootstrap';
@import '~datatables/media/css/jquery.dataTables.min.css';
@import '~animate.css/animate.css';

// @import 'bootstrap.css';
@import 'base';
@import 'layout';
@import 'mediaqueries';
@import '../../css/table';
@import '../../css/alert.flash';
@import "../../css/parent.base";