/***************************************************************************** HEADER
************************************************************************************/

.header-logo {
	max-height : 125px;
}

#header .dropdown-toggle {
	text-transform : uppercase;
	color          : #333333;
}

#header .header-ico {
	max-height : 25px;
}

#header-mobile .header-ico {
	max-height : 30px;
}

.dropdown-toggle .fa {
	font-size : 12px;
	top       : -4px;
	position  : relative;
	color     : #333333 !important;
}

#header-mobile {
	display : none;
}


/***************************************************************************** FOOTER
************************************************************************************/

#footer {
	/*background              : url("../gfx/accueil/bg.jpg") center center no-repeat;*/
	/*-webkit-background-size : cover;*/
	/*background-size         : cover;*/
	background-color : #333333;
}

#footer .titre {
	padding-top    : 30px;
	padding-bottom : 20px;
}

.footer-logo {
	max-height : 175px;
}


/***************************************************************************** SLIDER
************************************************************************************/

#slider {
	/*background : url("../gfx/accueil/bg.jpg") top center no-repeat;/**/
	-webkit-background-size : cover;
	background-size         : cover;
}

#slider .titre {
	padding-top    : 40px;
	padding-bottom : 30px;
}

#slider h1 {
	font-size   : 46px;
	line-height : 46px;
	text-shadow : 3px 5px 5px rgba(0, 0, 0, 0.8);
}

#slider h1 span {
	font-size   : 86px;
	line-height : 86px;
	font-weight : 700;
	display     : block;
}

#slider .sous-titre {
	font-size   : 46px;
	line-height : 46px;
	font-family : 'Dancing Script', cursive;
	text-shadow : 3px 5px 5px rgba(0, 0, 0, 0.8);
}


/***************************************************************************** PUBLICATION
************************************************************************************/


.card-publication-dotwrapper {
	min-height : 85px;
}

.card-publication-dotwrapper-2 {
	min-height : 50px;
}

.cadre-actualite-2 {
	margin-bottom : 2rem;
	padding-bottom : 1.5rem;
	border-bottom : 1px solid rgba(0,0,0,0.2);
}

.card-album img {
	width         : 100%;
	border-radius : 1.5rem;
	z-index       : 1;
}

.album-overlay {
	z-index                    : 2;
	top                        : 50%;
	bottom                     : 0;
	right                      : 0;
	left                       : 0;
	background                 : linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(255, 0, 0, 0));
	border-bottom-left-radius  : 1.5rem;
	border-bottom-right-radius : 1.5rem;
}


.card-album .album-titre {
	width   : 100%;
	z-index : 3;
}

.card-album .album-titre .card-album-dotwrapper, .card-album .album-titre .small2 {
	text-shadow : 3px 3px 5px rgba(0, 0, 0, 0.8);
}

.badge-agenda {
	width            : auto;
	bottom           : 15px;
	left             : 1.25rem;
	background-color : rgba(0, 0, 0, 0.8);
	font-size        : 13px;
	color            : #FFFFFF;
	text-shadow      : 3px 3px 5px rgba(0, 0, 0, 0.8);
	padding          : 0.5em 1em;
}

/************************************************************************ LIGHTSLIDER
************************************************************************************/

#PrevSlidePubli, #PrevSlideAlbum {
	background-color      : #FFFFFF;
	border                : 1px solid #DDDDDD;
	-webkit-border-radius : 40px;
	-moz-border-radius    : 40px;
	border-radius         : 40px;
	height                : 40px;
	width                 : 40px;
	top                   : calc(50% - 20px);
	position              : absolute;
	left                  : -5px;
	font-size             : 20px;
	cursor                : pointer;
}

#NextSlidePubli, #NextSlideAlbum {
	background-color      : #FFFFFF;
	border                : 1px solid #DDDDDD;
	-webkit-border-radius : 40px;
	-moz-border-radius    : 40px;
	border-radius         : 40px;
	height                : 40px;
	width                 : 40px;
	top                   : calc(50% - 20px);
	position              : absolute;
	right                 : -5px;
	font-size             : 20px;
	cursor                : pointer;
}


/************************************************************************ CONTACT
************************************************************************************/

.gmap {
	height : 300px;
}



/************************************************************************ TEMPLATE
************************************************************************************/

#template img.blog-post-content-img-right {
	max-width: 50%;
	margin-left: 1.5rem !important;
	margin-bottom: 1rem !important;
	float: right;
}

#template  img.blog-post-content-img-left {
	max-width: 50%;
	margin-right: 1.5rem !important;
	margin-bottom: 1rem !important;
	float: left;
}

#template  .navbar {
	border-bottom : 5px solid #dddddd;
}

#template  .navbar-nav .nav-item {
	padding-right : 10px;
	padding-left : 10px;
}

#template  .navbar-nav .nav-item:not(:last-child) {
	border-right: 1px solid #dddddd;
}

@media (max-width: 768px) {
	#template  .navbar-nav .nav-item:not(:last-child) {
		border-right: none;
	}
}


