
body {
	font-family      : 'Quicksand', Arial, sans-serif;
	background-color : #FFFFFF;
	color            : #333333;
	font-size        : 20px;
	line-height      : 26px;
	font-weight : 500;
}

.wrapper {
	max-width : 1440px;
	width     : 100%;
	margin    : 0 auto;
}

.small2 {
	font-size: 70%;
}

/******************************************************* COLORS */

.text-dark {
	color : #333333 !important;
}

.bg-dark {
	background-color : #333333 !important;
}

.text-general {
	color : #f38c20  !important;
}

.bg-general {
	background-color : #f38c20  !important;
}

.text-cuesmes {
	color : #669933 !important;
}

.bg-cuesmes {
	background-color : #669933 !important;
}

.bdr-color-cuesmes {
  border-color: #669933 !important;
}

.text-flenu {
	color : #E32325 !important;
}

.bg-flenu {
	background-color : #E32325 !important;
}

.bdr-color-flenu {
  border-color: #E32325 !important;
}

.text-jemappes {
	color : #4A87C8 !important;
}

.bg-jemappes {
	background-color : #4A87C8 !important;
}

.bdr-color-jemappes {
  border-color: #4A87C8 !important;
}

/******************************************************* FORM REWRITE */

.form-control {
	height: calc(3rem + 2px);
	padding: 0 1.50rem!important;
}

/******************************************************* ALERT REWRITE */

.alert {
	border-radius: 0.75rem;
}

/******************************************************* PAGINATION REWRITE */

.page-link {
	color: #333333;
}

.page-link:hover {
	color: #333333;
}

.page-item.active .page-link {
	background-color: #f38c20;
	border-color: #f38c20;
}

/******************************************************* BADGE REWRITE */

.badge {
	padding: 0.5em 1em;
	font-weight: 600;
	border-radius: 3rem;
}

/******************************************************* MODAL REWRITE */

.modal-content {
	border : none;
}

.modal-footer {
	justify-content: center!important;
}



/******************************************************* CARD REWRITE */

.card {
	border-radius: 1.5rem;
}

.card-img-top {
	width: 100%;
	border-top-left-radius: calc(1.5rem - 1px);
	border-top-right-radius: calc(1.5rem - 1px);
}

.card > .list-group:last-child .list-group-item:last-child {
	border-bottom-right-radius: 1.5rem;
	border-bottom-left-radius: 1.5rem;
}


/******************************************************* DROPDOWN REWRITE */


.dropdown-toggle::after, .dropleft .dropdown-toggle::before {
	display : none;
}

.dropdown-menu {
	padding : 0;
	top     : 5px !important;
}

.dropdown-item {
	padding : 0.5rem 1rem;
	font-weight : 500;
}

.dropdown-item:hover, .dropdown-item:focus {
	background-color : rgba(0, 0, 0, 0.2);
}

.dropdown-divider {
	margin     : 0 !important;
	border-top : 2px solid rgba(255, 255, 255, 0.2);
}

.dropdown-menu-center {
	right: auto!important;
	left: 50%!important;
	-webkit-transform: translate(-50%, 0)!important;
	-o-transform: translate(-50%, 0)!important;
	transform: translate(-50%, 0)!important;
	top:30px!important;
}


/******************************************************* LINK */

a {
	color           : #f38c20 ;
	text-decoration : none;
}

a:hover,
a:focus {
	color           : #f38c20 ;
	text-decoration : underline;
}

a:focus {
	outline        : thin dotted;
	outline        : 5px auto -webkit-focus-ring-color;
	outline-offset : -2px;
}


/******************************************************* BTN */

.btn {
	padding               : 15px 30px;
	-webkit-border-radius : 30px;
	-moz-border-radius    : 30px;
	border-radius         : 30px;
	font-size             : 20px;
	line-height           : 20px;
	border                : 3px solid;
	-webkit-transition    : all ease 0.3s;
	-moz-transition       : all ease 0.3s;
	-ms-transition        : all ease 0.3s;
	-o-transition         : all ease 0.3s;
	transition            : all ease 0.3s;
	font-weight           : 700 !important;
}

.btn-small {
	padding               : 10px 20px;
	-webkit-border-radius : 30px;
	-moz-border-radius    : 30px;
	border-radius         : 30px;
	font-size             : 16px;
	line-height           : 16px;

}

.btn:hover {
	background : none !important;
	color      : #333333 !important;
}

.btn-dark {
	background   : #333333 !important;
	border-color : #333333 !important;
	color        : #FFFFFF !important;
}

.btn-dark.active {
	background   : transparent !important;
	border-color : #333333 !important;
	color        : #333333 !important;
}

.btn-general {
	background   : #f38c20 !important;
	border-color : #f38c20 !important;
	color        : #FFFFFF !important;
}

.btn-general.active {
	background   : transparent !important;
	border-color : #f38c20 !important;
	color        : #333333 !important;
}

.btn-cuesmes {
	background   : #669933 !important;
	border-color : #669933 !important;
	color        : #FFFFFF !important;
}

.btn-cuesmes.active {
	background   : transparent !important;
	border-color : #669933 !important;
	color        : #333333 !important;
}

.btn-flenu {
	background   : #E32325 !important;
	border-color : #E32325 !important;
	color        : #FFFFFF !important;
}

.btn-flenu.active {
	background   : transparent !important;
	border-color : #E32325 !important;
	color        : #333333 !important;
}

.btn-jemappes {
	background   : #4A87C8 !important;
	border-color : #4A87C8 !important;
	color        : #FFFFFF !important;
}

.btn-jemappes.active {
	background   : transparent !important;
	border-color : #4A87C8 !important;
	color        : #333333 !important;
}


/******************************************************* BTN UP */

.btn-go-up {
	position : fixed;
	bottom   : 10px;
	right    : 10px;
	z-index  : 9998;
}

.btn-go-up a {
	background-color : rgba(0, 0, 0, 0.3);
	padding          : 7px 10px;
	display          : inline-block;
	color            : rgba(255, 255, 255, 0.5);
	transition       : all 0.5s ease;
}

.btn-go-up a:hover {
	background-color : #000000;
	color            : #FFFFFF;
}


/******************************************************* PADDING */

.p-0 { padding : 0; }

.p-t-0 { padding-top : 0px !important; }

.p-t-5 { padding-top : 5px !important; }

.p-t-10 { padding-top : 10px !important; }

.p-t-15 { padding-top : 15px !important; }

.p-t-20 { padding-top : 20px !important; }

.p-t-25 { padding-top : 25px !important; }

.p-t-30 { padding-top : 30px !important; }

.p-t-40 { padding-top : 40px !important; }

.p-b-0 { padding-bottom : 0px !important; }

.p-b-5 { padding-bottom : 5px !important; }

.p-b-10 { padding-bottom : 10px !important; }

.p-b-15 { padding-bottom : 15px !important; }

.p-b-20 { padding-bottom : 20px !important; }

.p-b-25 { padding-bottom : 25px !important; }

.p-b-30 { padding-bottom : 30px !important; }

.p-b-40 { padding-bottom : 40px !important; }

.p-l-0 { padding-left : 0px !important; }

.p-l-5 { padding-left : 5px !important; }

.p-l-10 { padding-left : 10px !important; }

.p-l-15 { padding-left : 15px !important; }

.p-l-20 { padding-left : 20px !important; }

.p-l-25 { padding-left : 25px !important; }

.p-l-30 { padding-left : 30px !important; }

.p-l-40 { padding-left : 40px !important; }

.p-r-0 { padding-right : 0px !important; }

.p-r-5 { padding-right : 5px !important; }

.p-r-10 { padding-right : 10px !important; }

.p-r-15 { padding-right : 15px !important; }

.p-r-20 { padding-right : 20px !important; }

.p-r-25 { padding-right : 25px !important; }

.p-r-30 { padding-right : 30px !important; }

.p-r-40 { padding-right : 40px !important; }


/******************************************************* MARGIN */

.m-0 { margin : 0; }

.m-t-0 { margin-top : 0px !important; }

.m-t-5 { margin-top : 5px !important; }

.m-t-10 { margin-top : 10px !important; }

.m-t-15 { margin-top : 15px !important; }

.m-t-20 { margin-top : 20px !important; }

.m-t-25 { margin-top : 25px !important; }

.m-t-30 { margin-top : 30px !important; }

.m-t-40 { margin-top : 40px !important; }

.m-b-0 { margin-bottom : 0px !important; }

.m-b-5 { margin-bottom : 5px !important; }

.m-b-10 { margin-bottom : 10px !important; }

.m-b-15 { margin-bottom : 15px !important; }

.m-b-20 { margin-bottom : 20px !important; }

.m-b-25 { margin-bottom : 25px !important; }

.m-b-30 { margin-bottom : 30px !important; }

.m-b-40 { margin-bottom : 40px !important; }

.m-l-0 { margin-left : 0px !important; }

.m-l-5 { margin-left : 5px !important; }

.m-l-10 { margin-left : 10px !important; }

.m-l-15 { margin-left : 15px !important; }

.m-l-20 { margin-left : 20px !important; }

.m-l-25 { margin-left : 25px !important; }

.m-l-30 { margin-left : 30px !important; }

.m-l-40 { margin-left : 40px !important; }

.m-r-0 { margin-right : 0px !important; }

.m-r-5 { margin-right : 5px !important; }

.m-r-10 { margin-right : 10px !important; }

.m-r-15 { margin-right : 15px !important; }

.m-r-20 { margin-right : 20px !important; }

.m-r-25 { margin-right : 25px !important; }

.m-r-30 { margin-right : 30px !important; }

.m-r-40 { margin-right : 40px !important; }

#modal-newsletter {
	.form-check {
		padding: 0;
	}

	.alert-form-error {
		font-size: 14px;

		p {
			margin-bottom: 5px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

.flash-dom {

	.alert {
		margin-top: 3rem !important;
	}

	.alert-body {
		.alert {
			margin-top: 3rem !important;
		}

		padding: 0;
	}
}
