/* LG */
@media (min-width : 1200px) {

	.gmap {
		height: 620px;
	}

}


/* MD */
@media (min-width : 992px) and (max-width : 1199px) {
	.gmap {
		height: 620px;
	}

}


/* SM */
@media (min-width : 768px) and (max-width : 991px) {
	#header .list-icones {display : none;}

	#slider .titre {
		padding-top    : 30px;
		padding-bottom : 20px;
	}

	#slider h1 {
		font-size : 36px;
		line-height : 36px;
	}

	#slider h1 span {
		font-size : 76px;
		line-height : 76px;
	}

	#slider .sous-titre {
		font-size : 40px;
		line-height : 40px;

	}

}


/* XS */
@media (max-width : 767px) {


	#header {display : none;}
	#header-mobile {display : block;}
	.header-logo {
		max-height: 90px;
	}

	#footer {
		text-align: center!important;
	}

	#slider .titre {
		padding-top    : 30px;
		padding-bottom : 20px;
	}

	#slider h1 {
		font-size : 24px;
		line-height : 28px;
	}

	#slider h1 span {
		font-size : 40px;
		line-height : 40px;
	}

	#slider .sous-titre {
		font-size : 30px;
		line-height : 30px;
	}

}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {


	#template img.blog-post-content-img-right, #template img.blog-post-content-img-left {
		max-width: 100%;
		width: 100%;
		margin-left: 0 !important;
		margin-right: 0 !important;
		margin-bottom: 1.5rem !important;
		float: none;
	}


}

