.text-short {
  text-overflow: ellipsis;
  /* Required for text-overflow to do anything */
  white-space: nowrap;
  overflow: hidden;
}

.no-nav-link {
  pointer-events: none !important;
  cursor: no-drop;
}

.copy-success-content {
  position: absolute;
  padding: 10px;
  border-radius: 2.5px;
  background-color: #aacdf2;
  color: #0069d9;
  top: -40px;
  left: 50%;
  font-size: 10px;
  transform: translateX(-50%);
  z-index: 10000;
  box-shadow: 0px 0px 8px rgba(0,0,0,.5);
  cursor: context-menu;
  white-space: nowrap;


  div {
    width: 10px;
    height: 10px;
    background-color: #aacdf2;
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
}